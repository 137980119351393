.requirment__form {
  max-width: 100%;
  .ant-select-selection-item-content {
    .label__name {
      display: none;
    }
  }
  @screen md {
  }
}

.range {
  .range-right {
    float: right;
  }
}
// .fd-custom-select {
//   // border-color: red;
//   border: 2px solid #257fd9;
//   border-radius: 6px;
// }
.fd-custom-select .ant-select-selection-item {
  color: #257fd9; /* Change to your desired text color */
}

.fd-custom-select .ant-select-arrow {
  color: #257fd9 !important; /* Change to your desired icon color */
}
.ant-picker-calendar-full .ant-picker-header .ant-picker-year-select {
  display: none;
}

.ant-fullcalendar-header {
  display: none;
}

.homepage__banner__slider {
  .slick-dots {
    position: absolute !important;
    bottom: -27px !important;
    li button:before {
      font-size: 14px !important;
      color: #999999;
    }
    .slick-active button:before {
      color: rgb(var(--color-primary));
      font-size: 18px !important;
    }
  }
  @media (max-width: 768px) {
    .slick-dots {
      display: none !important;
    }
  }
}
