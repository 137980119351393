.ant-slider .ant-slider-handle::before {
  background-color: transparent !important;
  inset-inline-start: -5px !important;
  inset-block-start: -5px !important;
  width: 20px !important;
  height: 20px !important;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 4px #1e7fd9 !important;
  width: 12px !important;
  height: 12px !important;
  inset-inline-start: -1px !important;
  inset-block-start: -1px !important;
}

.ant-slider {
  inset-block-start: 1px !important;
}
.ant-slider-track {
  background-color: #1e7fd9 !important;
}

.ant-tooltip-inner {
  span {
    color: #fff !important;
  }
}
